<template>
    <div class="personalRating">
		<watch-head :title="$t('社交聊天室')" :img="require('@/assets/bracelet_img/38.png')" />
        

        <div class="boxCard">
            <div class="main">
                <!-- <img @click="close" class="close" src="@/assets/bracelet_img/40.png" alt=""> -->
                <div class="roomTitle">{{ inFo.name ? inFo.name : $t('暂无名称') }}</div>
                <!-- <div class="subTitle">- 06 / 08  完成 -</div> -->
                <div class="socialRating">{{$t('挖矿收益')}} :</div>
                
				<div class="avatar">
					<!-- <img src="@/assets/bracelet_img/2.png" alt=""> -->
					<van-image
						class="image"
						lazy-load
						round
						:src="userInfo.avatar"
					/>
					<div class="text">{{ userInfo.level_no }}</div>
				</div>
				<div class="name">{{ userInfo.nickname ? userInfo.nickname : $t('暂无名称') }}</div>
				<!-- <div class="ranking">排行 : 10002</div> -->
				<van-rate v-model="inFo.grade" :size="40" color="#a7f264" void-color="#2a2a2a" />
				<div class="myRating">
					<span class="my">{{$t('我的评分')}}</span>
					<span>
						<span> :  {{ inFo.number }} </span>
						<span class="green">(+{{ inFo.variable }})</span>
						<span> / 100</span>
					</span>
				</div>
                    
				<div class="rewardDistri">
					<div class="titleReward">- {{$t('奖励分配')}} / IONZ -</div>
					<van-field class="number" v-model="inFo.ionz" readonly label-width="0" input-align="center" :placeholder="$t('奖励')"></van-field>
				</div>

				<!-- <div class="btn">完成</div> -->
            </div>


        </div>


        <!-- <div class="btm">
			<div class="left">
				<div class="box">
					<img src="@/assets/bracelet_img/5.png" alt="">
				</div>
			</div>
			<div class="right">
				<div class="ask">请求</div>
				<div class="invite">邀请</div>
			</div>
		</div> -->
    </div>
</template>

<script>
	import watchHead from '@/components/watchHead/index.vue'
	import { ScoredInfoApi } from '@/api/api'
	import { mapState } from 'vuex'
	export default {
		components: {
			watchHead
		},
		data() {
			return {
        // value: 0,
				// number: '800 IONZ',
				userInfo: {
					avatar: null,
					level_no: 0,
					nickname: null,
				},
				inFo: {
					grade: 0,
					ionz: 0,
					number: 0,
					variable: 0,
					name: '',
				},
				timer: null,
        loading:false,
			}
		},
    computed: {
			...mapState([
				'web3',
			]),
		},
		created(){ this.waitForGlobal(); },
		methods: {
			getInfo(){
				ScoredInfoApi({
					id: this.$route.query.id
				}).then(res => {
					if(res.code == 0){
						res.data.grade = Number(res.data.grade)
						this.inFo = res.data
					}
				})
			},
            //链接钱包
			waitForGlobal: function () {
				window.clearTimeout(this.timer);
				// 1. 刷新页面时是否已经连接钱包登录
				if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
					window.clearTimeout(this.timer);
					this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
					this.getInfo();
				} else {
					// 如果检测到没有连接钱包登录
					this.timer = setTimeout(
						this.waitForGlobal
					, 100);
				}
			},
               
		}
	}
</script>

<style lang="less" scoped>
    // div{
	// 	box-sizing: border-box;
	// }
    .personalRating{
        width: 100%;
		min-height: 100vh;
		padding-top: 75px;
		padding-bottom: 67.5px;
		background: url(~@/assets/bracelet_img/8.png) no-repeat;
		background-size: 100% 100%;
		color: #ffffff;
		

        .boxCard{
            padding: 30px 33.75px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .main{
                width: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 7.5px;
                position: relative;
                padding: 11.25px 11.25px 18.75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .close{
                    width: 22.5px;
                    height: 22.5px;
                    position: absolute;
                    right: 7.5px;
                    top: 7.5px;
                    z-index: 999;
                }
                .roomTitle{
                    font-family: 'TsangerYuYangT-W05';
                    font-size: 30px;
					margin-bottom: 18.75px;
                }
                .subTitle{
                    font-family: 'TsangerYuYangT-W05';
                    font-size: 12px;
                    // margin-bottom: 18.75px;
                }
                .socialRating{
                    width: 100%;
                    font-family: '071-SSRuiFengTi';
                    font-size: 30px;
                    margin-bottom: 11.25px;
                }
                .avatar{
					width: 67.5px;
					height: 67.5px;
					border-radius: 50%;
					background-color: #2b2b2b;
					border: 2px solid #2b2b2b;
					position: relative;
					// img{
					// 	width: 100%;
					// 	height: 100%;
					// 	border-radius: 50%;
					// }
					.image{
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
					.text{
						width: 30px;
						height: 30px;
						line-height: 30px;
						background-color: #2b2b2b;
						border-radius: 50%;
						position: absolute;
						right: -13px;
						bottom: 0;
						text-align: center;
						font-family: 'AeroMaticsDisplayBoldItalic';
						font-size: 18.75px;
					}
				}
				.name{
					font-family: 'AeroMaticsDisplayBoldItalic';
					font-size: 26.25px;
					margin-top: 7.5px;
					margin-bottom: 22.5px;
				}
				.ranking{
					font-family: 'TsangerYuYangT-W03';
					font-size: 15px;
					// margin-bottom: 22.5px;
				}
				.myRating{
					font-family: 'AeroMaticsDisplayBoldItalic';
					font-size: 18.75px;
					margin-top: 7.5px;
					.my{
						font-family: 'TsangerYuYangT-W03';
						font-size: 15px;
					}
					.green{
						color: #a7f264;
					}
				}
                  
				.rewardDistri{
					margin-top: 3px;
					padding: 3px 18.75px 11.25px;
					background-image: linear-gradient(237deg, rgba(118, 233, 214, 0.83) 0%, rgba(159, 174, 230, 0.83) 100%), 
					linear-gradient(#ffffff, #ffffff);
					background-blend-mode: normal, normal;
					border-radius: 11.25px;
					width: 100%;
					display: flex;
					align-items: center;
					flex-direction: column;
					.titleReward{
						font-family: 'TsangerYuYangT-W05';
						font-size: 15px;
						margin-bottom: 3px;
					}
					.van-field{
						background-color: #ffffff;
						border-radius: 15px;
					}
					.number{
						padding: 4.5px 11.25px;
						/deep/.van-field__control{
							font-family: 'AeroMaticsDisplayItalic';
							font-size: 18.75px;
							color: #2b2b2b;
						}
						/* WebKit, Blink, Edge */
						/deep/.van-field__control::-webkit-input-placeholder{
							font-size: 18.75px;
						}
						/* Mozilla Firefox 4 to 18 */
						/deep/.van-field__control:-moz-placeholder{
							font-size: 18.75px;
						}
						/* Mozilla Firefox 19+ */
						/deep/.van-field__control::-moz-placeholder{
							font-size: 18.75px;
						}
						/* Internet Explorer 10-11 */
						/deep/.van-field__control:-ms-input-placeholder{
							font-size: 18.75px;
						}
						/* Microsoft Edge */
						/deep/.van-field__control::-ms-input-placeholder{
							font-size: 18.75px;
						}
					}
				}
				.btn{
					margin-top: 75px;
					background-color: #a7f264;
					border-radius: 15px 3px 15px 3px;
					border: 2px solid rgba(0, 0, 0, 0.8);
					padding: 3px 75px;
					font-family: 'REEJI-PinboGB-Flash';
					font-size: 22.5px;
					color: #2b2b2b;
				}
            }
        }

		// .btm{
		// 	width: 100%;
		// 	display: flex;
		// 	align-items: center;
		// 	position: fixed;
		// 	bottom: 22.5px;
		// 	.left{
		// 		background-color: rgba(43, 43, 43, 0.5);
		// 		border-radius: 0 3px 3px 0;
		// 		padding: 3px 3px 3px 0;
		// 		margin-right: 3px;
		// 		.box{
		// 			padding: 3px 10px 3px 7.5px;
		// 			background-color: rgba(43, 43, 43, 0.9);
		// 			border-radius: 0 3px 3px 0;
		// 			border: solid 2px #7736fe;
		// 			border-left: none;
		// 			display: flex;
		// 			img{
		// 				width: 26px;
		// 				height: 18.75px;
		// 			}
		// 		}
		// 	}
		// 	.right{
		// 		background-color: rgba(43, 43, 43, 0.5);
		// 		border-radius: 3px 26.25px 3px 3px;
		// 		padding: 3.75px 20px 3.75px 7.5px;
		// 		display: flex;
		// 		align-items: center;
		// 		font-family: 'REEJI-PinboGB-Flash';
		// 		font-size: 18.75px;
		// 		color: #2b2b2b;
		// 		.ask{
		// 			background-color: #5c65cb;
		// 			box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
		// 			border-radius: 18.75px 3px 18.75px 3px;
		// 			padding: 2.5px 48.75px;
		// 			margin-right: 7.5px;
		// 		}
		// 		.invite{
		// 			background-image: linear-gradient(#a7f264, #a7f264), 
		// 			linear-gradient(#5c65cb, #5c65cb);
		// 			background-blend-mode: normal, normal;
		// 			box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
		// 			border-radius: 18.75px 3px 18.75px 3px;
		// 			padding: 2.5px 48.75px;
		// 		}
		// 	}
		// }
    }
</style>